import React, { useContext, useState, useEffect } from 'react'
import $ from 'jquery';
import Axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { isMobile } from 'react-device-detect';
import { baseUrl, baseUrlThinkly } from "../../apiContent/api.jsx";
import { Avatar, Card, CardMedia, Tabs, Tab, withStyles, CircularProgress } from '@material-ui/core';
import { CheckCircleOutlineRounded, Star } from '@material-ui/icons';
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import UserProfileMob from "./userProfileMob";
import "../css/userProfile.css";
import StarIcon from '../images/star.svg'
import Durgajasraj from '../images/durgajasraj.png'
import Audio_Icon from '../../thinklyLite/Images/audio-icon.svg'
import Video_Icon from '../../thinklyLite/Images/video-icon.svg'
import Header from '../common/header.jsx';
// import ParaByNameFromUrl from '../../common/paraByNameFromUrl.jsx';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import Faq from '../../common/faq.jsx';
import { getAnalytics, isSupported, logEvent } from 'firebase/analytics'
import FirebaseApp from '../../thinklyLite/loginPage/firebase.js';
import LoveGuruFaq from '../../common/loveGuruFaq.jsx';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const StyledTabs = withStyles({
    indicator: {
        indicator: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
            position: 'sticky',
            "& > span": {
                maxWidth: 40,
                width: "100%",
                //   backgroundColor: "#fffcef",
            },
        },
    },
})((props) => (<Tabs {...props} variant="fullWidth" TabIndicatorProps={{ children: <span /> }} />));

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        color: "#000000",
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(16),
        // marginRight: theme.spacing(0),
        "&:focus": {
            opacity: 1,
            outline: 'none',
            backgroundColor: 'none'
        },
    },
}))((props) => <Tab disableRipple {...props} />);

const UserProfile = (props) => {
    const emailValidate = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const history = useHistory()
    const BASE_URL = useContext(baseUrl);
    const BASE_URL_THINKLY = useContext(baseUrlThinkly);
    const { handleSubmit, formState } = useForm(); //for form submit
    const [getProfileDetail, setprofileDetail] = useState()
    const [getpenName, setpenName] = useState()
    const [value, setValue] = React.useState(0)
    const [getThinkliesByAuthorData, setThinkliesByAuthorData] = useState()
    const [getPublicationByAuthorData, setPublicationByAuthorData] = useState()
    const [showModal, setShowModal] = useState(false);
    const [viewFullProfile, setviewFullProfile] = useState(false)
    const [starCount, setstarCount] = useState(0)
    const [Remarks, setRemarks] = useState()
    const [currency, setcurrency] = useState()
    const [finalAmount, setfinalAmount] = useState()
    const [showDataOnHeader, setshowDataOnHeader] = useState(false)
    const [aboutUser, setaboutUser] = useState()
    const [loginStatus, setloginStatus] = useState()
    const [Loader, setLoader] = useState(false)
    const [UserDetail, setUserDetail] = useState()
    const [senderName, setsenderName] = useState()
    const [emailID, setemailID] = useState()
    const [numberInfo, setnumberInfo] = useState()
    const [SupportButton, setSupportButton] = useState(true)
    const [LoggedInUSerID, setLoggedInUSerID] = useState()
    const [IpAdress, setIpAdress] = useState()
    const [LoveGuruTemplate, setLoveGuruTemplate] = useState(false)

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const getData = async () => {
            // const res = await Axios.get('https://geolocation-db.com/json/')
            // console.log("response of ip address ", res.data.IPv4);
            // if (res.data.IPv4 !== undefined && res.data.IPv4 !== null) {
            //     setIpAdress(res.data.IPv4)
            // }
            await fetch('https://api.ipify.org?format=json')
                .then(response => response.json())
                .then(data => setIpAdress(data.ip))
                .catch(error => console.log(error))
        }
        getData()  //to get IPv4
        var thePath = window.location.href;
        var path = thePath.substring(thePath.lastIndexOf('/') + 1)
        if (path.toLowerCase() == 'loveguru') {
            console.log('inside condition@@@@@@@@@@@@@@@');
            setLoveGuruTemplate(true)
        }
        if (window.penName !== undefined && window.userStauts !== undefined) {
            console.log("inside window.penName ", window.penName, window.userStauts);
            getUserProfileDateils(window.penName)
            setloginStatus(window.userStauts)
        } else {
            console.log('inside elsle condition');
            const loggedIn_ID = window.sessionStorage.getItem('UserID')
            if (loggedIn_ID !== undefined && loggedIn_ID !== null) {
                setLoggedInUSerID(loggedIn_ID)
            } else {
                setLoggedInUSerID(0)
            }
            // const paramValue = ParaByNameFromUrl('username')
            console.log(path);
            getUserProfileDateils(path)
        }

    }, [])

    function getUserProfileDateils(data) {
        console.log("usesr profile details data base url@", BASE_URL, data);
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": "123456",
                "UserID": "3223"
            },
        };
        Axios.get(`${BASE_URL}User/GetDetailsByPenName/${data}`, config)
            .then((res) => {
                console.log("Get user Details By PenName@@@@@", res.data);
                if (res.data.responseCode === '00') {
                    isSupported().then((result) => {
                        if (result) {
                            const analytics = getAnalytics(FirebaseApp)
                            logEvent(analytics, 'STAR_USERDETAIL', {
                                receiverUsername: data,
                                senderUserID: LoggedInUSerID,
                                ipAddress: IpAdress
                            })
                        }
                    })
                    console.log("inside GetAllUserProfileByUserID @@@@@@@@", res.data.responseData);
                    setprofileDetail(res.data.responseData.Details)
                    const penName = res.data.responseData.Details.profileDetails.penName
                    setpenName(penName.charAt(0) === '@' ? penName.substring(1) : penName)
                    const aboutData = res.data.responseData.Details.profileDetails.aboutMe
                    setaboutUser(aboutData.trim());
                    getThinkliesByAuthor(res.data.responseData.Details.profileDetails.userID)  //api call params passed
                    getPublicationByAuthor(res.data.responseData.Details.profileDetails.userID)  //api call params passed
                } else if (res.data.responseCode === '03') {
                    history.push('/login')
                }
            })
            .catch((err) => {
                console.log("getUserProfileDateils error in catch", err);
            });
    }

    useEffect(() => {
        setshowDataOnHeader(true)
    }, [getProfileDetail, LoveGuruTemplate])


    function getThinkliesByAuthor(user_id) {
        var config = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "DeviceID": "123456",
                "UserID": user_id
            },
            data: {
                "UserID": user_id,
                "StartIndex": 0,
                "EndIndex": 10
            }
        };
        Axios(`${BASE_URL}Thinkly/GetUserThinklies/`, config)
            .then((res) => {
                console.log(res);
                if (res.data.responseCode === '00') {
                    console.log("getThinkliesByAuthor @@@@@@@@", res.data.responseData);
                    setThinkliesByAuthorData(res.data.responseData)
                } else if (res.data.responseCode === '03') {
                    setThinkliesByAuthorData(res.data.responseData)
                }
            })
            .catch((err) => {
                console.log("getThinkliesByAuthor error in catch", err);
            });
    }

    function getPublicationByAuthor(user_id) {
        var config = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "DeviceID": "123456",
                "UserID": user_id
            },
            data: {
                "UserID": user_id,
                "StartIndex": 0,
                "EndIndex": 10
            }
        };
        Axios(`${BASE_URL}User/GetUserPublications/`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    console.log("setPublicationByAuthorData @@@@@@@@", res.data.responseData);
                    setPublicationByAuthorData(res.data.responseData)
                } else if (res.data.responseCode === '03') {
                    setPublicationByAuthorData(res.data.responseData)
                }
            })
            .catch((err) => {
                console.log("setPublicationByAuthorData error in catch", err);
            });
    }

    function getAmountForStar(star_count) {
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": "123456",
                "UserID": "3223"
            },
        };
        Axios.get(`${BASE_URL_THINKLY}Star/GetStarPriceDetails`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    console.log("inside getAmountForStar @@@@@@@@", res.data.responseData);
                    var fixAmount = res.data.responseData.starPriceData
                    var amount = star_count * parseInt(fixAmount.perStarPrice)
                    console.log(amount);
                    setcurrency(fixAmount.currencySymbol)
                    setfinalAmount(amount)
                }
            })
            .catch((err) => {
                console.log("getAmountForStar error in catch", err);
            });
    }

    const handleStar = (star) => {
        console.log("handle star function triggered", star);
        setstarCount(star)  //set star in state
        setSupportButton(false)
        getAmountForStar(star) //function
        if (star === 1) {
            document.getElementById("threeStar").className = "numberCircle"
            document.getElementById("fiveStar").className = "numberCircle"
            document.getElementById("Stars").className = "numberCircle"
            document.getElementById("oneStar").className = "numberCircleBorder"
        } else if (star === 3) {
            document.getElementById("oneStar").className = "numberCircle"
            document.getElementById("fiveStar").className = "numberCircle"
            document.getElementById("Stars").className = "numberCircle"
            document.getElementById("threeStar").className = "numberCircleBorder"
        } else if (star === 5) {
            document.getElementById("oneStar").className = "numberCircle"
            document.getElementById("threeStar").className = "numberCircle"
            document.getElementById("Stars").className = "numberCircle"
            document.getElementById("fiveStar").className = "numberCircleBorder"
        } else if (star === 10) {
            document.getElementById("oneStar").className = "numberCircle"
            document.getElementById("threeStar").className = "numberCircle"
            document.getElementById("fiveStar").className = "numberCircle"
            document.getElementById("Stars").className = "numberCircleBorder"
        }
    }

    function onSubmit() {
        if (UserDetail === 'star') {
            isSupported().then((result) => {
                if (result) {
                    const analytics = getAnalytics(FirebaseApp)
                    logEvent(analytics, 'STAR_SUPPORT', {
                        username: getpenName,
                        senderUserID: LoggedInUSerID,
                        ipAddress: IpAdress
                    })
                }
            })
            var quantity = $('#qty').val();
            if (quantity !== undefined && quantity !== null && quantity > 0 && finalAmount !== undefined && finalAmount !== null) {
                $('#userContactInfo').modal('show')
            } else {
                document.getElementById('starCountError').innerHTML = 'Select how many Stars you wish to gift?'
            }
        } else if (UserDetail === 'userInfo') {
            isSupported().then((result) => {
                if (result) {
                    const analytics = getAnalytics(FirebaseApp)
                    logEvent(analytics, 'STAR_PAYMENT', {
                        username: getpenName,
                        senderUserID: LoggedInUSerID,
                        ipAddress: IpAdress
                    })
                }
            })
            if (senderName !== undefined && emailID !== undefined && numberInfo !== undefined) {
                console.log('inside 1st condition');
                if (emailID.match(emailValidate) && numberInfo.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
                    console.log('inside 2nd condition');
                    setLoader(true)
                    return new Promise(resolve => {
                        const form = document.createElement('form');
                        form.method = 'post'
                        form.action = process.env.REACT_APP_PAYMENT_GATEWAY
                        const data = [
                            { name: 'qty', value: starCount },
                            { name: 'receiver', value: getpenName },
                            { name: 'sender', value: "" },
                            { name: 'channel', value: "giftStars" },
                            { name: 'amount', value: finalAmount },
                            { name: 'remarks', value: Remarks !== undefined ? Remarks : '' },
                            { name: 'name', value: senderName },
                            { name: 'emailid', value: emailID },
                            { name: 'phone', value: numberInfo }
                        ]
                        for (let x = 0; x < data.length; x++) {
                            const hiddenField = document.createElement('input');
                            hiddenField.type = 'text';
                            hiddenField.name = data[x].name;
                            hiddenField.value = data[x].value;
                            hiddenField.style.display = 'none'
                            form.appendChild(hiddenField);
                        }
                        console.log("DONE", form, data);
                        document.body.appendChild(form);
                        form.submit();
                        $('#userContactInfo').modal('hide');
                        resolve();
                    });
                } else {
                    document.getElementById('infoPlease').innerHTML = 'Please Enter valid EmailID and Contact number'
                }
            } else {
                document.getElementById('infoPlease').innerHTML = 'Please Enter name, Email ID and Contact number to continue'
            }
        }
    }

    // const handleStarInfo = () => {
    //     var quantity = $('#qty').val();
    //     if (quantity !== undefined && quantity !== null && quantity > 0 && finalAmount !== undefined && finalAmount !== null) {
    //         console.log("inside star info");
    //         history.push({
    //             pathname: '/payment-gateway',
    //             search: `?qty=${starCount}&receiver=${getpenName}&amount=${finalAmount}&remarks=${Remarks}&productId=`
    //         })
    //     } else {
    //         document.getElementById('starCountError').innerHTML = 'Select how many Stars you wish to gift?'
    //     }
    // }

    return (<>
        {showDataOnHeader && getProfileDetail !== undefined && <Header showContentForUserProfile={showDataOnHeader} userProfile={getProfileDetail} loginStatus={loginStatus} />}
        {getProfileDetail !== undefined && getProfileDetail !== null && getThinkliesByAuthorData !== undefined && getPublicationByAuthorData !== undefined ? <>
            {isMobile ? <UserProfileMob userProfileJson={getProfileDetail} userThinklyJson={getThinkliesByAuthorData} userPublilcationJson={getPublicationByAuthorData} /> :
                <>
                    {Loader ? <div className='row d-flex my-5'>
                        <CircularProgress className='my-auto mx-auto' style={{ color: '#FAA422', height: '50px', width: '50px' }} />
                    </div> : <div className='container'>
                        <div className='row'>
                            <div className='col-3'>
                                {getProfileDetail.profileDetails.profileImage !== undefined ?
                                    <Avatar src={getProfileDetail.profileDetails.profileImage.charAt(0) === '@' ? getProfileDetail.profileDetails.profileImage.substring(1) : getProfileDetail.profileDetails.profileImage} alt="user" className='float-right' style={{ height: '180px', width: '180px' }} />
                                    : <Avatar src={<AssignmentIndOutlinedIcon />} />
                                }
                            </div>
                            <div className='col-9'>
                                <div className="card left-content" style={{ height: '500px' }}>
                                    <div className='fs-16 fw-bold'> {getpenName}  </div>
                                    <div className='fs-16 fw-mid mb-3'>{getProfileDetail.profileDetails.firstName} {getProfileDetail.profileDetails.lastName !== undefined && getProfileDetail.profileDetails.lastName}</div>
                                    <p className='fs-15'>{aboutUser}
                                        {/* <p className='fs-15'>{aboutUser.length > 200 ? aboutUser.slice(0, 200) + ('...') : aboutUser} */}
                                        <span className='fc-link fw-mid pointer ml-4' data-toggle="modal" data-target="#myModal">View Full Profile</span>
                                    </p>

                                    {getpenName !== undefined && getpenName.toUpperCase() === 'DURGAJASRAJ' && <img src={Durgajasraj} alt="durgajasraj" className='my-4' width="100%" height="100%" style={{ objectFit: 'contain', objectPosition: 'center' }} />}

                                    {getProfileDetail.profileDetails.isSupportEnabled === true && <>
                                        {<Card className='mt-4' style={{ padding: '10px 20px 20px 20px', background: '#fff', width: '50%', height: 'auto', overflow: 'initial' }}>
                                            <form name="paymentGateway" onSubmit={handleSubmit(onSubmit)}>
                                                <div className='text-center'>
                                                    {LoveGuruTemplate ? <p className='fw-mid'>Send <Star className='star-color' /> to Love Guru </p>
                                                        : <p className='fw-mid'>Support <Star className='star-color' /> to {getpenName}</p>}
                                                    <div className='row ml-1'>
                                                        <div className='col-1' style={{ marginLeft: '-7px', marginRight: '22px' }}>
                                                            <img src={StarIcon} style={{ width: '36px', height: '36px' }} />
                                                        </div>
                                                        <div className='col-1' style={{ fontSize: '22px' }}> x </div>
                                                        <div className='col-2 mt-1'>
                                                            <span class="numberCircle pointer" name="starCount" id="oneStar" onClick={() => handleStar(1)}> 1 </span>
                                                        </div>
                                                        <div className='col-2 mt-1'>
                                                            <span class="numberCircle pointer" name="starCount" id="threeStar" onClick={() => handleStar(3)}> 3 </span>
                                                        </div>
                                                        <div className='col-2 mt-1'>
                                                            <span class="numberCircle pointer" name="starCount" id="fiveStar" onClick={() => handleStar(5)}> 5 </span>
                                                        </div>
                                                        <div className='col-3 mt-1'>
                                                            <span class="numberCircle pointer" name="starCount" id="Stars" style={{ padding: '10px 10px' }} onClick={() => handleStar(10)}> 10 </span>
                                                        </div>
                                                        {/* <div className='col-3'>
                                                <input type="text" maxLength={5} class="numberCircleInput" id="Stars" onClick={() => handleStar('n')} placeholder="More" />
                                            </div> */}
                                                    </div>
                                                    <input type='text' name='qty' id='qty' value={starCount} style={{ display: 'none' }} />
                                                    <textarea className='mt-3 w-96' name='remarks' id='remarks' rows={3} cols={40} type="text" maxLength={1000} value={Remarks} onChange={(e) => setRemarks(e.target.value)} style={{ outline: 'none', border: '1px solid lightgray' }} placeholder="Your personal message here... (Optional)"></textarea>
                                                    {starCount > 0 ? '' : <div id="starCountError" className='error-msg'></div>}
                                                    <button onClick={() => setUserDetail('star')} className={`mt-3 pointer fw-mid border-radius-4 fc-white border-none height-button fs-18 w-96 ${SupportButton ? 'bg-gray' : 'primary-bg-color'}`} id='getStarValue' disabled={SupportButton}>
                                                        {LoveGuruTemplate ? <>Send {!SupportButton && starCount}<Star style={{ color: 'antiquewhite', marginTop: '-3px' }} /> to {getpenName} {currency}{finalAmount} </>
                                                            : <> Support {!SupportButton && starCount}<Star style={{ color: 'antiquewhite', marginTop: '-3px' }} /> to {getpenName} {currency}{finalAmount}</>}
                                                    </button>
                                                    {/* <button className='mt-3 pointer fw-mid border-radius-4 fc-white border-none height-button fs-18 w-96 primary-bg-color'>
                                                Support {starCount}<Star style={{ color: 'antiquewhite' }} /> to {getpenName} {currency}{finalAmount}
                                            </button> */}
                                                </div>
                                                {/* modalpopup for user info */}
                                                <div id="userContactInfo" class="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
                                                    <div class="modal-dialog modal-dialog-centered">
                                                        <div class="modal-content">
                                                            <button type="button" class="close text-right pr-2" data-dismiss="modal" >&times;</button>
                                                            <div class="modal-body px-5 pb-4 pt-1">
                                                                <h5 className='fs-22 mb-4'>We need some details to send you a receipt</h5>
                                                                <input type='text' placeholder='Your name' value={senderName} onChange={(e) => setsenderName(e.target.value)} style={{ fontSize: '20px', border: 'none', outline: 'none', width: '100%' }} />
                                                                <br /> <br />
                                                                <input type='text' placeholder='Your email ID' value={emailID} onChange={(e) => setemailID(e.target.value)} style={{ fontSize: '20px', border: 'none', outline: 'none', width: '100%' }} />
                                                                <br /><br />
                                                                <input type='text' maxlength="10" placeholder='Your mobile number' value={numberInfo} onChange={(e) => setnumberInfo(e.target.value)} style={{ fontSize: '20px', border: 'none', outline: 'none', width: '100%' }} />
                                                                <br /><br />
                                                                {<div id="infoPlease" className='error-msg'></div>}
                                                                <div className='text-center'>
                                                                    <button type='submit' className='mt-3 pointer fw-mid border-radius-4 fc-white border-none height-button fs-18 w-50 primary-bg-color' onClick={() => setUserDetail('userInfo')} >Continue</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </Card>}
                                    </>}
                                    <div className="mr-4">
                                        {LoveGuruTemplate ? <LoveGuruFaq /> : <Faq />}
                                    </div>

                                    {/* if viewFullProfile is true then show thinkly and publication list below for now not in use */}
                                    {viewFullProfile && <> <div className='mt-5'>
                                        <p className='font-weight-bold' style={{ fontSize: '18px' }}>Publications by this Author</p>
                                        {/* infinite={true} autoPlay={truse} autoPlaySpeed={2000} arrows={false} */}
                                        <Carousel responsive={responsive}>
                                            {getPublicationByAuthorData !== null && getPublicationByAuthorData.length > 0 ? getPublicationByAuthorData.map((obj) => {
                                                var img_extension = '.' + (obj.publicationImage.split(/[#?]/)[0].split('.').pop())
                                                return (<Card className="mb-4" style={{ width: '140px', boxShadow: 'none', background: 'rgba(247, 247, 247, 0.49)' }}>
                                                    {(/.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(img_extension) ?
                                                        <CardMedia component="img" height="160" image={obj.publicationImage.charAt(0) === '@' ? obj.publicationImage.substring(1) : obj.publicationImage} style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }} alt="publication profile" />
                                                        : <div style={{ background: '#ea7f00', height: '160px' }}></div>
                                                    }
                                                    <div className='px-2'>
                                                        <text style={{ fontSize: '14px', fontWeight: 'bold' }}>{obj.publicationName}</text> <br />
                                                        <a href='#morepublication' data-toggle="modal" data-target="#myModal" onClick={() => setShowModal(true)} style={{ color: '#2baadf', fontSize: '12px', fontFamily: 'sans-serif' }}>View the Publication</a>
                                                    </div>
                                                </Card>)
                                            }) : <div className='text-center'> No Data Available </div>}
                                        </Carousel>
                                    </div>
                                        <div className='mt-5'>
                                            <p className='font-weight-bold' style={{ fontSize: '18px' }}>Thinklies by this Author</p>
                                            <StyledTabs value={value} onChange={handleChangeTabs} aria-label="styled tabs" >
                                                <StyledTab label="All" />
                                                <StyledTab label="Audio" />
                                                <StyledTab label="Video" />
                                            </StyledTabs>
                                        </div>
                                        <div className='mt-4'>
                                            {getThinkliesByAuthorData !== null && getThinkliesByAuthorData.length > 0 ? <>
                                                {(value === 0 ? <div className='row'>
                                                    {getThinkliesByAuthorData.map((obj) => {
                                                        var image1 = obj.postData.postImages[0];
                                                        var isAudio = obj.postData.audioURL;
                                                        var isVideo = obj.postData.videoURL;
                                                        return (<div className='col-4 mb-4'>
                                                            <Card className="card-view-publication">
                                                                <div className='row d-flex' style={{ padding: '5px 5px 5px 20px' }}>
                                                                    {image1 !== undefined ? <div className='col-4' style={isAudio !== "" || isVideo !== "" ? { marginBottom: '-24px' } : {}}>
                                                                        <img className='img-fluid Upublilcation-image' src={image1.charAt(0) === '@' ? image1.substring(1) : image1} alt="" />
                                                                        {isAudio !== undefined && isAudio !== "" ? <img src={Audio_Icon} className='thinkly-type-icon1' /> :
                                                                            isVideo !== undefined && isVideo !== "" ? <img src={Video_Icon} className='thinkly-type-icon1' /> : ''}
                                                                    </div> : <div className='col-4 Upublilcation-no-image'>
                                                                        {isAudio !== undefined && isAudio !== "" ? <img src={Audio_Icon} className='thinkly-type-icon2' /> :
                                                                            isVideo !== undefined && isVideo !== "" ? <img src={Video_Icon} className='thinkly-type-icon2' /> : ''}
                                                                    </div>}
                                                                    <div className='col-8 my-auto'>
                                                                        <p className='' style={{ fontSize: '14px', lineHeight: '1', marginBottom: '0px' }}> {obj.postData.postTitle} </p>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </div>)
                                                    })}
                                                </div> : value === 1 ? <div className='row'>
                                                    {getThinkliesByAuthorData.map((obj) => {
                                                        var image1 = obj.postData.postImages[0];
                                                        var isAudio = obj.postData.audioURL;
                                                        return (<>
                                                            {isAudio !== undefined && isAudio !== "" && <div className='col-4 mb-4' >
                                                                <Card className="card-view-publication">
                                                                    <div className='row d-flex' style={{ padding: '5px 5px 5px 20px' }}>
                                                                        {image1 !== undefined ? <div className='col-4' style={{ marginBottom: '-24px' }}>
                                                                            <img className='img-fluid Upublilcation-image' src={image1.charAt(0) === '@' ? image1.substring(1) : image1} alt="" />
                                                                            <img src={Audio_Icon} className='thinkly-type-icon1' />
                                                                        </div> : <div className='col-4 Upublilcation-no-image'>
                                                                            <img src={Audio_Icon} className='thinkly-type-icon2' />
                                                                        </div>}
                                                                        <div className='col-8 my-auto'>
                                                                            <p className='' style={{ fontSize: '14px', lineHeight: '1', marginBottom: '0px' }}> {obj.postData.postTitle} </p>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </div>}
                                                        </>)
                                                    })}
                                                </div> : <div className='row'>
                                                    {getThinkliesByAuthorData.map((obj) => {
                                                        var image1 = obj.postData.postImages[0];
                                                        var isVideo = obj.postData.videoURL;
                                                        return (<>
                                                            {isVideo !== undefined && isVideo !== "" && <div className='col-4 mb-4' >
                                                                <Card className="card-view-publication">
                                                                    <div className='row d-flex' style={{ padding: '5px 5px 5px 20px' }}>
                                                                        {image1 !== undefined ? <div className='col-4' style={{ marginBottom: '-24px' }}>
                                                                            <img className='img-fluid Upublilcation-image' src={image1.charAt(0) === '@' ? image1.substring(1) : image1} alt="" />
                                                                            <img src={Video_Icon} style={{ marginTop: '-90px', marginLeft: '6px' }} />
                                                                        </div> : <div className='col-4 Upublilcation-no-image'>
                                                                            <img src={Video_Icon} style={{ marginTop: '15px', marginLeft: '6px' }} />
                                                                        </div>}
                                                                        <div className='col-8 my-auto'>
                                                                            <p className='' style={{ fontSize: '14px', lineHeight: '1', marginBottom: '0px' }}> {obj.postData.postTitle} </p>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </div>}
                                                        </>)
                                                    })}
                                                </div>)}
                                            </> : <div className='text-center'> No Data Available </div>}
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>}
                </>
            }
        </> : <div style={{ padding: '300px 0px', textAlign: 'center' }}>
            <CircularProgress aria-label="Loading..." />
        </div>
        }
    </>)
}

export default UserProfile;

{/* <div>
        <img src={Filter} alt='filter' style={{ width: '22px' }} />
        <span style={{ fontSize: '16px', color: '#8e8e8e' }}>nf Art, technology, etc.</span>
    </div> */}
{/* thinkly name */ }
// const t_name = obj.postData.postTitle;
// const count = 16;
// const thinkly_name = t_name.slice(0, count) + (t_name.length > count ? "..." : "");
{/* subCategory */ }
// const sub_cat = obj.postData.subcategoryname;
// const cat_name = sub_cat.replace(/,(?=[^\s])/g, ", ")
// const subCat_name = cat_name.split(",", 2)
// const subCat_count = cat_name.split(",").length
// <div className='row'>
//     <div className='col-4'>
//         <img className='img-top-on-card' src={Profile_pic} alt="publication profile" />
//         <Card className="p-2">
//             <div className='row'>
//                 <div className='col-10'>
//                     <Typography style={{ fontSize: '12px' }}> 12 Days ago </Typography>
//                     <Typography style={{ fontSize: '10px' }}> 5 min Read </Typography>
//                 </div>
//             </div>
//             {/* <CardContent> */}
//             <Typography className='pr-2 mt-3' style={{ fontSize: '16px' }}> Thinklies - name of all type thinklies </Typography>
//             {/* </CardContent> */}
//             <CardHeader avatar={<Avatar style={{ width: '25px', height: '25px' }}> :) </Avatar>} title="CardHeader Example" />
//         </Card>
//     </div>